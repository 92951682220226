import Swiper from 'swiper/bundle';

export function index(){
  const fvslide = document.getElementById('fvslide');
  if(fvslide){
		new Swiper(fvslide, {
			spaceBetween: 0,
			effect: 'fade',
			speed: 2000,
      loop: true,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
			},
    })
  }
  const rankingSlide = document.getElementById('rankingSlide');
  if(rankingSlide){
		new Swiper(rankingSlide, {
      slidesPerView: 'auto',
			spaceBetween: 32,
      centeredSlides: true,
      loop: true,
			speed: 600,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
    })
  }

	const recmSlider = document.getElementById('recmSlider');
	if(recmSlider){
		new Swiper(recmSlider, {
			slidesPerView: 'auto',
			loop: true,
			spaceBetween: 32,
      centeredSlides: true,
			//parallax: true,
			/*pagination: {
        el: recmSliderPageing,
        type: 'progressbar',
			},*/
		});
	}

};