import Swiper from 'swiper/swiper-bundle.min';
import * as Masonry from 'masonry-layout';
import * as imagesLoaded from 'imagesloaded';

export function marche(controller , scene){
	const gridGallery = document.getElementById('masonry');
	if(gridGallery){
		imagesLoaded(gridGallery, function(){
			new Masonry(gridGallery , {
				itemSelector: '.grid-item'
			});
			gridGallery.classList.add('active');
		});
	}
};