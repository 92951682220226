import {scrolEvents} from './default';
import {index} from './index';
import {marche} from './marche';
import {shop} from './shop';
import {detail} from './detail';
import {vm} from './vm';
require("fslightbox");

document.addEventListener('DOMContentLoaded', function() {    
  vm();
  scrolEvents();
  index();
  marche();
  shop();
  detail();
});