export function shop(controller, scene) {
	const wooCartArea = document.getElementById('wooCartArea');
	const ajaxAddToCart = document.querySelectorAll('.addCartClass');
	const wooCartCounter = document.getElementById('wooCartCounter');
	const cartimgCrone = document.getElementById('cartimgCrone');
	let timer;

	if (wooCartArea) {
		wooCartCounter.remove();
	}

	if (ajaxAddToCart.length > 0 && wooCartCounter != null) {
		ajaxAddToCart.forEach((item) => {
			item.addEventListener('click', (e) => {
				//console.log(e.target);
				if (e.target.classList.contains('added_to_cart') != true) {
					let numb = Number(wooCartCounter.innerHTML) + 1;
					let img = item.parentElement.firstElementChild.querySelector('img').src;

					item.classList.add('active');
					cartimgCrone.style.backgroundImage = 'url(' + img + ')';
					cartimgCrone.classList.add('active');

					wooCartCounter.innerHTML = numb;
					wooCartCounter.classList.add('active');

					timer = setTimeout(() => {
						//e.currentTarget.classList.remove('active');
						item.classList.remove('active');
						cartimgCrone.classList.remove('active');
						wooCartCounter.classList.remove('active');
					}, 800);
				}
			});
		});
	}

	const alertOrdermaid = document.getElementById('alert-ordermaid');
	const openbtn = document.getElementById('ordermaid-openbtn');
	const wooCustumUpload = document.getElementById('woo-custum-upload');
	const wooCustumUploadClose = document.getElementById('woo-custum-upload-close');
	if (openbtn && wooCustumUpload && wooCustumUploadClose) {
		openbtn.addEventListener('click', (e) => {
			wooCustumUpload.classList.add('active');
		});
		wooCustumUploadClose.addEventListener('click', (e) => {
			wooCustumUpload.classList.remove('active');
		});
	} else if (alertOrdermaid) {
		alertOrdermaid.remove();
	}
};
