import Swiper from 'swiper/bundle';
export function detail(controller , scene){

  const detailareaSlides = document.querySelector('.detailarea__slides .swiper-container')
  const detailareaThumb = document.querySelector('.detailarea__thumb .swiper-container')
  

  if(detailareaSlides && detailareaThumb){

    let thumbs;

    if(window.innerWidth < 1120){
      thumbs = new Swiper(detailareaThumb, {
        spaceBetween: 10,
        slidesPerView: 'auto',
        direction: 'horizontal',
        watchSlidesProgress: true,
      })
    }else{
      thumbs = new Swiper(detailareaThumb, {
        spaceBetween: 0,
        slidesPerView: 'auto',
        direction: 'vertical',
        watchSlidesProgress: true,
      })
    }

		new Swiper(detailareaSlides, {
			slidesPerView: 1,
			loop: false,
			spaceBetween: 0,
      speed: 800,
      shortSwipes: false,
      simulateTouch: false,
      thumbs: {
        swiper: thumbs,
      },
      on: {
        init: function () {
          zoomer()
        },
      },
		});
  }
  
  const detailareaThumbLink = document.querySelectorAll('.detailarea__thumb .swiper-container a');
  detailareaThumbLink.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
    });
  });

  const producttab = document.getElementById('producttab');
  if(producttab){
    const tabs = producttab.querySelectorAll('#producttabNav li a');
    tabs.forEach((el) => {
      el.addEventListener('click', clickTab);
    });
  }

  function zoomer(){
    const imageContainers = document.querySelectorAll('.detailarea__slides .swiper-container .swiper-slide div');
    const wrapElem = document.querySelector('.detailarea__slides');
    imageContainers.forEach(container => {
      container.addEventListener('mousemove', (e) => {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
  
        // 要素の位置を取得
        const elementRect = wrapElem.getBoundingClientRect();
        const elementLeft = wrapElem.clientWidth - wrapElem.clientWidth / 4;
        const elementTop = wrapElem.clientHeight;
  
        // 要素内の相対的なマウス座標を計算
        const relativeX = mouseX - elementLeft;
        const relativeY = mouseY - elementTop;
        container.style.left = -1 * relativeX + 'px';
        container.style.top = -1 * relativeY + 'px';
      });
    });
  }

  function clickTab(e){
    const elem = e.target;
    const target = elem.dataset.open;
    const tabs = producttab.querySelectorAll('#producttabNav li a');
    const content = producttab.querySelectorAll('#producttab .producttab__content');
    tabs.forEach((el) => {
      el.classList.remove('active');
    });
    elem.classList.add('active');

    content.forEach((el) => {
      el.classList.remove('active');
    });
    document.getElementById(target).classList.add('active');

  }
};
