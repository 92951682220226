import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function scrolEvents(controller , scene){

    ScrollTrigger.create({
      trigger: '#site-app',
      //markers: true,
      start: "top 25%",
      once: false,
      toggleClass: {targets: '#site-header', className: "active"},
    });


    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
			header: '#site-header',
			offset: 0,
			speed: 1000,
			easing: 'easeInOutQuart',
			speedAsDuration: true,
			durationMin: 1000
    });
	
}